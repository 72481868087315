import react, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CFormSelect,
    CButton,
    CFormFeedback
} from "@coreui/react";
import axios from "axios";
import { CiCircleRemove } from "react-icons/ci";

const EditSubscription = (props) => {
    const handleShow = () => {
        setErrors({});
    };

    const [coursesField, setCoursesField] = useState(['']);
    const [courseList, setCourseList] = useState([]);

    const [videosField, setVideosField] = useState(['']);
    const [videoList, setVideoList] = useState([]);

    const [programsField, setProgramsField] = useState(['']);
    const [programList, setProgramList] = useState([]);

    const [errors, setErrors] = useState({});

    const subscriptionData = props?.subscription;
    const programsData = props?.subscriptionPrograms;
    const coursesData = props?.subscriptionCourses;
    const videosData = props?.subscriptionVideos;

    const statusMapping = {
        1: "Active",
        2: "Inactive",
        3: "Deleted"
    }

    const statusValue = subscriptionData ? subscriptionData.status : '';
    const statusName = statusMapping[statusValue];

    const initialFormData = {
        name: subscriptionData ? subscriptionData.name : "",
        description: subscriptionData ? subscriptionData.description : "",
        price: subscriptionData ? subscriptionData.price : "",
        status: statusName || "",
        addPrograms: [],
        deletePrograms: [],
        addCourses: [],
        deleteCourses: [],
        // addModules: [],
        // deleteModules: [],
        addVideos: [],
        deleteVideos: []
    };

    const [formData, setFormData] = useState(initialFormData);
    useEffect(() => {
        setFormData(initialFormData);
    }, [subscriptionData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };
    const user = parseUserFromLocalStorage();
    const { token } = user;

    useEffect(() => {
        const getProgramList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-program`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProgramList(response?.data?.data);

            } catch (error) {
                console.error("Error fetching programs:", error);
            }
        };


        const getCourseList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-courses`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCourseList(response?.data?.data);

            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        const getVideoList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setVideoList(response?.data?.data);

            } catch (error) {
                console.error("Error fetching videos:", error);
            }
        };
        if (user) {
            getProgramList();
            getCourseList();
            getVideoList();
        }
    }, [])

    useEffect(() => {
        if (programsData && programsData.length > 0) {
            const subsProgramsData = programsData.map(program => ({
                programId: program?.content_id,
                programName: program?.name,
                subscriptionPlanId: program?.subscription_plan_id
            }));
            setProgramsField(subsProgramsData);
        } else {
            setProgramsField([]);
        }
    }, [programsData]);

    useEffect(() => {
        if (coursesData && coursesData.length > 0) {
            const subsCoursesData = coursesData.map(course => ({
                courseId: course?.content_id,
                courseName: course?.name,
                subscriptionPlanId: course?.subscription_plan_id
            }));
            setCoursesField(subsCoursesData);
        } else {
            setCoursesField([]);
        }
    }, [coursesData]);

    useEffect(() => {
        if (videosData && videosData.length > 0) {
            const subsVideosData = videosData.map(video => ({
                videoId: video?.content_id,
                videoName: video?.video_title,
                subscriptionPlanId: video?.subscription_plan_id
            }));
            setVideosField(subsVideosData);
        } else {
            setVideosField([]);
        }
    }, [videosData]);

    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const handleProgramOptions = (programId) => {
        setSelectedProgramId(programId);
    }

    const handleProgramChange = (index, e) => {
        const selectedProgramId = parseInt(e.target.value, 10);
        const selectedProgram = programList.find(program => program.program_id === selectedProgramId);

        setFormData((prev) => {
            const newAddPrograms = [...(prev.addPrograms || [])];

            if (selectedProgram && selectedProgram.program_id) {
                newAddPrograms[index] = selectedProgram.program_id;

                if (programsField[index] && programsField[index].programId) {
                    const newDeletePrograms = [...(prev.deletePrograms || []), programsField[index].programId];
                    return {
                        ...prev,
                        addPrograms: newAddPrograms.filter(Boolean),
                        deletePrograms: newDeletePrograms
                    };
                }
            } else {
                console.error("Selected program is undefined or missing program_id property for addProgram");
            }

            return {
                ...prev,
                addPrograms: newAddPrograms
            };
        });
        setErrors((prev) => ({ ...prev, addPrograms: "" }));
    };

    const addProgram = () => {
        const newProgramsData = [...programsField, 'newProgram'];
        setProgramsField(newProgramsData);
    };

    const removeProgram = (index) => {
        const newProgramsData = [...programsField];

        setFormData((prev) => {
            let programIdTobeDeleted;
            if (programsField[index].programId) {
                programIdTobeDeleted = programsField[index].programId;
            } else {
                programIdTobeDeleted = formData.addPrograms[index];
            }
            const newDeletePrograms = [...(prev.deletePrograms || []), programIdTobeDeleted];
            const newAddPrograms = [...(prev.addPrograms || [])];
            newAddPrograms.splice(index, 1);
            return {
                ...prev,
                deletePrograms: newDeletePrograms,
                addPrograms: newAddPrograms.filter(Boolean)
            };
        });

        newProgramsData.splice(index, 1);
        setProgramsField(newProgramsData);
    };

    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const handleCourseOptions = (course_id) => {
        setSelectedCourseId(course_id);
    }

    const handleCourseChange = (index, e) => {
        const selectedCourseId = parseInt(e.target.value, 10);
        const selectedCourse = courseList.find(course => course.course_id === selectedCourseId);

        setFormData((prev) => {
            const newAddCourses = [...(prev.addCourses || [])];

            if (selectedCourse && selectedCourse.course_id) {
                newAddCourses[index] = selectedCourse.course_id;
                if (coursesField[index] && coursesField[index].courseId) {
                    const newDeleteCourses = [...(prev.deleteCourses || []), coursesField[index].courseId];
                    return {
                        ...prev,
                        addCourses: newAddCourses.filter(Boolean),
                        deleteCourses: newDeleteCourses
                    };
                }
            } else {
                console.error("Selected course is undefined or missing course_id property for addCourse");
            }

            return {
                ...prev,
                addCourses: newAddCourses
            };
        });
        setErrors((prev) => ({ ...prev, addCourses: "" }));
    };

    const addCourse = () => {
        const newCoursesData = [...coursesField, 'newCourse'];
        setCoursesField(newCoursesData);
    };

    const removeCourse = (index) => {
        const newCoursesData = [...coursesField];

        setFormData((prev) => {
            let courseIdTobeDeleted;
            if (coursesField[index].courseId) {
                courseIdTobeDeleted = coursesField[index].courseId;
            } else {
                courseIdTobeDeleted = formData.addCourses[index];
            }
            const newDeleteCourses = [...(prev.deleteCourses || []), courseIdTobeDeleted];
            const newAddCourses = [...(prev.addCourses || [])];
            newAddCourses.splice(index, 1);
            return {
                ...prev,
                deleteCourses: newDeleteCourses,
                addCourses: newAddCourses.filter(Boolean)
            };
        });

        newCoursesData.splice(index, 1);
        setCoursesField(newCoursesData);
    };

    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const handleOptions = (video_id) => {
        setSelectedVideoId(video_id);
    }

    const handleVideoChange = (index, e) => {
        const selectedVideoId = parseInt(e.target.value, 10);
        const selectedVideo = videoList.find(video => video.video_id === selectedVideoId);

        setFormData((prev) => {
            const newAddVideos = [...(prev.addVideos || [])];

            if (selectedVideo && selectedVideo.video_id) {
                newAddVideos[index] = selectedVideo.video_id;

                if (videosField[index] && videosField[index].videoId) {
                    const newDeleteVideos = [...(prev.deleteVideos || []), videosField[index].videoId];
                    return {
                        ...prev,
                        addVideos: newAddVideos.filter(Boolean),
                        deleteVideos: newDeleteVideos
                    };
                }
            } else {
                console.error("Selected video is undefined or missing video_id property for addVideos");
            }

            return {
                ...prev,
                addVideos: newAddVideos.filter(Boolean),
            };
        });

        setErrors((prev) => ({ ...prev, addVideos: "" }));
    };

    const addVideo = () => {
        const newVideosData = [...videosField, "newVideo"];
        setVideosField(newVideosData);
    };

    const removeVideo = (index) => {
        const newVideosData = [...videosField];

        setFormData((prev) => {
            let videoIdTobeDeleted;
            if (videosField[index].videoId) {
                videoIdTobeDeleted = videosField[index].videoId;
            } else {
                videoIdTobeDeleted = formData.addVideos[index]
            }
            const newDeleteVideos = [...(prev.deleteVideos || []), videoIdTobeDeleted];
            const newAddVideos = [...(prev.addVideos || [])];
            newAddVideos.splice(index, 1);
            return {
                ...prev,
                deleteVideos: newDeleteVideos,
                addVideos: newAddVideos.filter(Boolean)
            };
        });

        newVideosData.splice(index, 1);
        setVideosField(newVideosData);
    };

    const [flag, setFlag] = useState(false);
    const handleEditSubsFlag = (flag) => {
        props.editSubsFlag(flag);
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            await axios.put
                (`${process.env.REACT_APP_API_BASE_URL}/update-subscription/${subscriptionData.subscription_plan_id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            handleEditSubsFlag(setFlag(true));
            props.onHide();

        } catch (err) {
            console.log("error in editing subscription", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg':"#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Subscription
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Program Name"
                            name="name"
                            placeholder="Enter a program name"
                            onChange={handleChange}
                            value={formData.name}
                            isInvalid={!!errors.name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.name}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.description}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Subscription Status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            required
                        >
                            <option>Active</option>
                            <option>Inactive</option>
                            <option>Deleted</option>
                        </CFormSelect>
                    </CCol>

                    {/* programs */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Programs</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addProgram}
                                style={btn}
                            >Add</CButton>
                        </div>
                        <div>
                            {programsField && programsField.map((program, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a program in subscription"
                                            onChange={(e) => handleProgramChange(index, e)}
                                            required
                                        >
                                            <option value={program.programId}
                                                onClick={() => handleProgramOptions(program.programId)}>
                                                {program.programName}
                                            </option>
                                            {programList && programList.map((options) => (
                                                program.programId !== options.program_id ?
                                                    <option key={options.program_id}
                                                        value={options.program_id}
                                                        onClick={() => handleProgramOptions(options.program_id)}>
                                                        {options.name}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeProgram(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* courses */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Courses</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addCourse}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div style={{
                            // display: "flex",
                            // flexDirection: "row",
                            // flexWrap: "wrap",
                        }}>
                            {coursesField && coursesField.map((course, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a course in subscription"
                                            onChange={(e) => handleCourseChange(index, e)}
                                            required
                                        >
                                            <option value={course.courseId}
                                                onClick={() => handleCourseOptions(course.courseId)}>
                                                {course.courseName}
                                            </option>
                                            {courseList && courseList.map((options) => (
                                                course.courseId !== options.course_id ?
                                                    <option key={options.course_id}
                                                        value={options.course_id}
                                                        onClick={() => handleCourseOptions(options.course_id)}>
                                                        {options.name}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeCourse(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* modules */}
                    {/* <div>
                        <h6 style={{ fontWeight: "normal" }}>Modules</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addModule}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div>
                            {modulesField && modulesField.map((module, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a module in subscription"
                                            onChange={(e) => handleModuleChange(index, e)}
                                            required
                                        >
                                            <option value={module.moduleId}
                                                onClick={() => handleModuleOptions(module.moduleId)}>
                                                {module.moduleName}
                                            </option>
                                            {moduleList && moduleList.map((options) => (
                                                module.moduleId !== options.module_id ?
                                                    <option key={options.module_id}
                                                        value={options.module_id}
                                                        onClick={() => handleModuleOptions(options.module_id)}>
                                                        {options.name}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeModule(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div> */}

                    {/* videos */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Videos</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addVideo}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div>
                            {videosField && videosField.map((video, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a video in subscription"
                                            // value={tag.tagName}
                                            onChange={(e) => handleVideoChange(index, e)}
                                            required
                                        >
                                            <option value={video.videoId}
                                                onClick={() => handleOptions(video.videoId)}>
                                                {video.videoName}</option>
                                            {videoList && videoList.map((options) => (
                                                video.videoId !== options.video_id ?
                                                    <option key={options.video_id} value={options.video_id}
                                                        onClick={() => handleOptions(options.video_id)}>
                                                        {options.video_title}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeVideo(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                        {/* {errors.addVideos ? <CFormFeedback style={{ color: "red" }}>
                            {errors.addVideos}</CFormFeedback> : <></>} */}
                    </div>

                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditSubscription;
