import React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import routes from "./routes";
import ProgressModal from './views/pages/progressModal/ProgressModal';
import './scss/style.scss';
import Login from './views/pages/login/Login';
import ProtectedRoute from './ProtectedRoute';
import ForgotPassword from './views/pages/login/ForgotPassword';
import ResetPassword from './views/pages/login/ResetPassword';

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/reset-password',
      element: <ResetPassword />
    },
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        { path: '/', element: <Outlet /> },
        ...routes.filter(item => item && item.path && item.element)
          .map(item => ({
            path: item?.path ?? '/login',
            element: item?.element ?? <Outlet />
          }))
      ]
    }
  ]);

  return (
    <React.Fragment>
      <RouterProvider router={router} />
      <ProgressModal />
    </React.Fragment>
  );
};

export default App;

