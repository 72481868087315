import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
    CButton,
    CButtonGroup,
    CContainer,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow, CPagination, CPaginationItem, CSpinner, CCol, CForm, CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AddFaculty from "./AddFaculty";
import EditFaculty from "./EditFaculty";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import '../../styling/Pagination.css';
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import MouseOverPopup from '../../mouseOver/MouseOverPopup';
import { useNavigate } from "react-router-dom";
import '../../styling/Table.css'
import { CiViewList } from "react-icons/ci";
import { GoSearch } from "react-icons/go";

const FacultyList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state?.sidebarShow);

    const [showAddFacultyModal, setShowAddFacultyModal] = useState(false);
    const [showEditFacultyModal, setShowEditFacultyModal] = useState(false);
    const [showDeleteConfirmFacultyModal, setShowDeleteConfirmFacultyModal] = useState(false);
    const [selectedFaculty, setSelectedFaculty] = useState(null);

    const [faculty, setFaculty] = useState([]);
    const [flag, setFlag] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };

    const user = parseUserFromLocalStorage();
    const { token } = user;

    useEffect(() => {
        const getFaculty = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-faculty`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setFaculty(response?.data?.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching faculty:", error);
            }
        };

        if (user) {
            getFaculty();
            setFlag(false);
        }
    }, [flag]);

    const handleAddFacultyFlag = (addFacultyFlag) => {
        setFlag(addFacultyFlag);
    }

    const handleEditFacultyFlag = (editFacultyFlag) => {
        setFlag(editFacultyFlag);
    }

    const updateFaculty = async (e, faculty) => {
        setShowEditFacultyModal(true);
        setSelectedFaculty(faculty);
    }

    const handleFacultyDeleteConfirmation = (e, faculty) => {
        setShowDeleteConfirmFacultyModal(true);
        setSelectedFaculty(faculty);
    }

    const deleteFaculty = async (e, faculty) => {
        try {
            e.preventDefault();
            setShowDeleteConfirmFacultyModal(false);
            const faculty_id = faculty?.faculty_id;
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete-faculty/${faculty_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setFlag(true);
        } catch (error) {
            console.log("delete-faculty error",error);
        }
    }

    const [stateSearch, setStateSearch] = useState(false);
    const [searchError, setSearchError] = useState("");
    const [searchData, setSearchData] = useState([]);

    const handleInputChange = (e) => {
        setStateSearch(true);
        setSearchQuery(e.target.value);
        if (e.target.value.trim() === '') {
            setSearchData(displayFaculty);
            setSearchError("");
        } else {
            filterData(e.target.value);
        }
    };

    const filterData = (query) => {
        const filteredData = displayFaculty.filter(faculty =>
            faculty.first_name.toLowerCase().includes(searchQuery) ||
            faculty.last_name.toLowerCase().includes(searchQuery) ||
            faculty.credential.toLowerCase().includes(searchQuery) ||
            faculty.email.toLowerCase().includes(searchQuery)
        );

        if (filteredData.length === 0) {
            setSearchError("No data found");
        } else {
            setSearchData(filteredData);
            setSearchError("");
        }
    };

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 25;
    const pageVisited = pageNumber * itemsPerPage;

    const displayFaculty = faculty.slice(pageVisited, pageVisited + itemsPerPage);
    const pageCount = Math.ceil(faculty.length / itemsPerPage);
    
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    const truncateText = (text, wordsCount) => {
        const words = text.split(' ');
        const truncatedText = words.slice(0, wordsCount).join(' ');
        return truncatedText + (words.length > wordsCount ? '...' : '');
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a",
        '--cui-btn-padding-y': "5px"
    }

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <CHeader className="d-flex">
                    <CContainer>
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() =>
                                dispatch({ type: "set", sidebarShow: !sidebarShow })
                            }
                        >
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                            <h4 style={{ marginRight: '3vw' }}>Faculty List</h4>
                            <CCol sm={4}>
                                <CForm className="d-flex">
                                    <CFormInput
                                        type="text"
                                        placeholder="Search"
                                        className="me-2 rounded-pill"
                                        style={{ width: "20vw" }}
                                        aria-label="Search"
                                        onChange={handleInputChange}
                                        value={searchQuery}
                                    />
                                    <span style={{
                                        marginLeft: "-3vw",
                                        marginTop: "1vh"
                                    }}><GoSearch /> </span>
                                </CForm>
                            </CCol>
                        </CHeaderNav>
                        <CHeaderNav>
                            <CButtonGroup>
                                <CButton
                                    style={btn} variant="outline"
                                    onClick={() => setShowAddFacultyModal(true)}
                                >
                                    Add Faculty
                                </CButton>
                            </CButtonGroup>
                        </CHeaderNav>
                    </CContainer>
                </CHeader>
                <div className="ms-4 mt-5 me-4">
                    <CTable striped>
                        <CTableHead style={tableHeader}>
                            <CTableRow>
                                <CTableHeaderCell style={{ width: '20%', paddingLeft: "3vw" }}>
                                    Faculty Name
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '20%' }}>
                                    Credential
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '25%' }}>
                                    Email
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '20%' }}>
                                    Address
                                </CTableHeaderCell>
                                <CTableHeaderCell>
                                    Actions
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody style={{ padding: "5px" }}>
                            {stateSearch ? (searchError !== "" ?
                                (<CTableRow className="text-center">
                                    <CTableDataCell colSpan={6}>{searchError}</CTableDataCell>
                                </CTableRow>) :
                                searchData && searchData.map((faculty, index) => (
                                    <CTableRow
                                        v-for="item in tableItems"
                                    >
                                        <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                            {faculty.first_name}  {faculty.last_name}
                                        </CTableDataCell>
                                        <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                            <span>{faculty.credential}</span>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <span>{faculty.email}</span>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <MouseOverPopup description={`${faculty.address1} 
                                                ${faculty.address2}, ${faculty.city},
                                                ${faculty.state} ${faculty.country}`}>
                                                <div>
                                                    <span>
                                                        {truncateText(
                                                            `${faculty.address1} 
                                                            ${faculty.address2}, ${faculty.city},
                                                             ${faculty.state} ${faculty.country}`, 5)}
                                                    </span>
                                                </div>
                                            </MouseOverPopup>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div className="d-flex mt-2 me-5 cursor-pointer">
                                                <div
                                                    style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                    onClick={(e) => navigate(`/faculty-details?faculty_id=${faculty.faculty_id}`)}
                                                >
                                                    <CiViewList />
                                                </div>
                                                <div
                                                    style={{ color: "darkgreen", marginRight: "1vw", cursor: "pointer" }}
                                                    onClick={(e) => updateFaculty(e, faculty)}
                                                >
                                                    <AiTwotoneEdit />
                                                </div>
                                                <span style={{ color: "red", marginRight: "1vw", cursor: "pointer" }}
                                                    onClick={(e) => handleFacultyDeleteConfirmation(e, faculty)}
                                                >
                                                    <AiTwotoneDelete />
                                                </span>
                                            </div>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))
                            ) : isLoading ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="6">
                                    <CSpinner />
                                </CTableDataCell>
                            </CTableRow> : (displayFaculty.length === 0 ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="6">
                                    <span>No data to show</span>
                                </CTableDataCell>
                            </CTableRow> : displayFaculty.map((faculty, index) => (
                                <CTableRow
                                    v-for="item in tableItems"
                                >
                                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                        {faculty.first_name}  {faculty.last_name}
                                    </CTableDataCell>
                                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                        <span>{faculty.credential}</span>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <span>{faculty.email}</span>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <MouseOverPopup description={`${faculty.address1} 
                                                ${faculty.address2}, ${faculty.city},
                                                ${faculty.state} ${faculty.country}`}>
                                            <div>
                                                <span>
                                                    {truncateText(
                                                        `${faculty.address1} 
                                                            ${faculty.address2}, ${faculty.city},
                                                             ${faculty.state} ${faculty.country}`, 5)}
                                                </span>
                                            </div>
                                        </MouseOverPopup>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div className="d-flex mt-2 me-5 cursor-pointer">
                                            <div
                                                style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                onClick={(e) => navigate(`/faculty-details?faculty_id=${faculty.faculty_id}`)}
                                            >
                                                <CiViewList />
                                            </div>
                                            <div
                                                style={{ color: "darkgreen", marginRight: "1vw", cursor: "pointer" }}
                                                onClick={(e) => updateFaculty(e, faculty)}
                                            >
                                                <AiTwotoneEdit />
                                            </div>
                                            <span style={{ color: "red", marginRight: "1vw", cursor: "pointer" }}
                                                onClick={(e) => handleFacultyDeleteConfirmation(e, faculty)}
                                            >
                                                <AiTwotoneDelete />
                                            </span>
                                        </div>
                                    </CTableDataCell>
                                </CTableRow>
                            )))
                            }
                        </CTableBody>
                    </CTable>
                </div>
                {displayFaculty.length > 0 && !searchError &&
                    (<CPagination align="center" aria-label="Page navigation example" className="cursor-pointer">
                        <CPaginationItem
                            aria-label="Previous"
                            onClick={() => setPageNumber(pageNumber - 1)}
                            disabled={pageNumber === 0}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </CPaginationItem>

                        {Array.from({ length: pageCount }, (_, index) => (
                            <CPaginationItem
                                key={index + 1}
                                active={pageNumber === index}
                                onClick={() => setPageNumber(index)}
                            >
                                {index + 1}
                            </CPaginationItem>
                        ))}

                        <CPaginationItem
                            aria-label="Next"
                            onClick={() => setPageNumber(pageNumber + 1)}
                            disabled={pageNumber === pageCount - 1}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </CPaginationItem>
                    </CPagination>)}
            </div>
            <AddFaculty
                show={showAddFacultyModal}
                onHide={() => { setShowAddFacultyModal(false) }}
                addFacultyFlag={handleAddFacultyFlag} />
            <EditFaculty
                show={showEditFacultyModal}
                onHide={() => setShowEditFacultyModal(false)}
                faculty={selectedFaculty}
                editFacultyFlag={handleEditFacultyFlag}
            />
            <ConfirmationModal
                show={showDeleteConfirmFacultyModal}
                handleClose={() => { setShowDeleteConfirmFacultyModal(false) }}
                handleConfirm={(e) => deleteFaculty(e, selectedFaculty)}
            />
        </div>
    );
};

export default FacultyList;
