import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = () => {
    const [tokenInDB, setTokenInDB] = useState(null);

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };

    const user = parseUserFromLocalStorage();
    const { email, token } = user;

    const getUser = async () => {
        try {
            if (!email || !token) {
                console.error("Invalid user data: Missing email or token");
                window.location.replace('/login');
                return;
            }
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-users-by-email/${email}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            if (response?.data?.data?.length > 0) {
                const fetchedUser = response.data.data[0];
                setTokenInDB(fetchedUser.token);
            } else {
                throw new Error('User data not found');
            }
        } catch (error) {
            console.error("Error fetching user:", error.message || error);
        }
    };

    const checkTokenMismatch = async () => {
        try {
            if (!token) {
                console.error("Invalid user data: Token missing in local storage");
                window.location.replace('/login');
                return;
            }

            await getUser();
            const localStorageToken = token;
            if (tokenInDB && tokenInDB !== localStorageToken) {
                localStorage.removeItem("user");
                window.location.replace('/login');
            }
        } catch (error) {
            console.error("Token mismatch:", error.message || error);
            window.location.replace('/login');
        }
    };

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 401) {
                    localStorage.removeItem('user');
                    if (window.location.pathname !== '/login') {
                        window.location.replace('/login');
                    }
                }
                return Promise.reject(error);
            }
        );
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => checkTokenMismatch(), 3000);
        return () => clearInterval(interval);
    }, [tokenInDB, user]);
    
    return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
